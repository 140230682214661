import { chain, trimEnd } from 'lodash';
import URI from 'urijs';

import { readableUrl } from '@wix/communities-blog-client-common/dist/src/services/uri';

let _sectionUrl = '';

export const initOuterUrl = sectionUrl => (_sectionUrl = sectionUrl);

export const getRawOuterUrl = (path, sectionUrl) => {
  const url = new URI(sectionUrl || _sectionUrl).unicode();
  const location = new URI(path).unicode();
  const pathname = trimEnd(readableUrl(url.pathname()), '/') + location.pathname();
  // addQuery breaks for encoded characters
  const query = chain([url.query(), location.query()])
    .compact()
    .join('&')
    .value();
  url.pathname(pathname);
  url.query(query);
  url.hash(location.hash() || url.hash());
  return url;
};

const getOuterUrl = (path, sectionUrl) => readableUrl(getRawOuterUrl(path, sectionUrl));

export default getOuterUrl;
