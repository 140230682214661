import PropTypes from 'prop-types';
import React from 'react';

const DirectionProviderWrapper = ({ children }) => (
  <div id="direction-provider-wrapper">{React.Children.only(children)}</div>
);

DirectionProviderWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DirectionProviderWrapper;
