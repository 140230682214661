import { get } from 'lodash';

let _debugEnabled = false;

export const initDebug = debugEnabled => (_debugEnabled = debugEnabled);

// eslint-disable-next-line no-console
const logger = level => (...message) => _debugEnabled && console[level](...message);

export const debug = {
  debug: logger('debug'),
  info: logger('info'),
  log: logger('log'),
  warn: logger('warn'),
  error: logger('error'),
  trace: logger('trace'),
};

const stack = get(new Error(), 'stack', '').toString();
const isDev = process.env.NODE_ENV !== 'production';

export const isVerboseDebugAllowed = isDev || stack.includes('sled.wix.dev');
